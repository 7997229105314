<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="sf_box1">
        <div class="item-box" v-for="(item, index) in barNameList" :key="index" :data-current="index">
          <router-link :to="{ path: '/content', query: { id: item.id } }">
            <div class="znx_nr">
              <div class="znx_nr1">
                <img src="../../assets/zf_youu.png" alt="" />
              </div>
              <div class="znx_nr2">
                <div>{{ $t('reward') }}</div>
                  <div class="znx_nr3">
                    <div class="znx_nr4">
                      <img src="../../assets/jb (2).png" alt="" />
                      <div>{{ item['attach'][1] }}</div>
                    </div>
                    <div class="znx_nr5">{{ item.timestamp }}</div>
                  </div>
                  <span v-show="item.hastake != 1" class="dot-box"></span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="znx_yh" @click="loadMore">{{ $t('more') }}</div>
      </div>

      <el-container>
        <tabBar />
      </el-container>
    </div>
  </div>
</template>

<script>
// import CommonAside from "../../components/CommonAside";
// import TopRes from "../../components/TopRes.vue";
import tabBar from "../../components/tabBar.vue";
import { emailList } from '../../api/index.js'
export default {
  data() {
    return {
      barNameList: [],
      goer: {
        page: 1,
        id: ''
      },
    };
  },
  components: {
    // CommonAside,
    // TopRes,
    tabBar,
  },
  methods: {
    znxx() {
      emailList().then((res) => {
        if (res) {
          this.barNameList = res.data.data

        }

      })
    },
    loadMore() {
      this.goer.page++
      const confign = {
        data: this.goer
      };
      emailList().then((res1) => {
        let aa = res1.data.data
        this.barNameList = this.barNameList.concat(aa);
      })
    }
  },
  mounted() {
    this.znxx();
  }
};
</script>

<style scoped>
.right {
  padding-top: 70px;
}

.item-box {
  width: 70%;
}

.sf_box1 {
  width: 80%;
  height: 650px;
  /* margin-top: 70px; */
  height: auto;
  margin: 0 auto;
  /* margin-top: 70px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.znx_nr {
  /* width: 70%;
  height: 100px;
  margin: 0 auto; */
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  border-radius: 10px;
  /* margin-top: 20px; */
  padding-top: 12px;
}

.znx_nr1 {
  width: 11%;
  height: 100px;
  line-height: 130px;
  margin-left: 20px;
}

.znx_nr img {
  width: 60px;
  height: 40px;
}

.znx_nr2 {
  color: #fff;
  font-size: 20px;
  width: 80%;
  height: 30%;
  position: relative;
}

.znx_nr2 .dot-box {
  width: 12px;
  height: 12px;
  background-color: #f00;
  border-radius: 12px;
  position: absolute;
  top: 8px;
  right: 0;
}

.znx_nr3 {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: space-between;
}

.znx_nr4 {
  display: flex;
  /* width: 70px; */
  height: 30px;
  background-color: black;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px 12px;
  gap: 10px;
}

.znx_nr4 img {
  width: 22px;
  height: 22px;
}

.znx_yh {
  color: #fff;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  background: linear-gradient( 270deg, #E2A5E5 0%, #ACAAEA 54%, #79C0FE 100%);
  cursor: pointer;
  width: 200px;
  height: 45px;
  line-height: 45px;
  border-radius: 12px;
}
</style>
