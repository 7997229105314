<template>
  <div class="topp">
    <div class="uyt" v-if="Token">
      <div class="iopp">
        <img class="sx" src="../assets/img5.png" style="width: 20px; height: 20px" alt="" />
        <div class="je">{{ $t('mouy') }}{{ totalcoin }}</div>
        <router-link to="/gomoney">
          <img class="zj" src="../assets/img6.png" style="width: 40px; height: 40px" alt="" />
        </router-link>
      </div>
      <el-button @click="drawerr = true" type="primary" style="background: transparent; border: 0;">
        <img class="txtop" :src="avatar" style="width: 50px; height: 50px" alt="" />
      </el-button>
      <el-drawer class="drawer-box" title="我是标题" :show-close="false" :wrapper-closable="true" append-to-body
        :visible.sync="drawerr" :with-header="false">
        <div class="grzx_top">
          <div class="grzx_tre">
            <div class="grzx_zp">
              <img :src="avatar" style="width: 50px; height: 50px" alt="" />
            </div>
            <div class="grzx_id">
              <div class="gezx_fz">
                <div>{{ uid }}</div>
                <img src="../assets/img8.png" style="width: 20px; height: 20px;cursor: pointer;" alt="" @click="copyData" />
              </div>
            </div>
          </div>
          <div class="grzx_dh">
            <img src="../assets/phone.png" alt="" />
            <div>{{ phone1 }}</div>
          </div>
        </div>
        <div class="grzx_vip">
          <router-link class="link-box" to="/VipSrc" @click.native="gotoPage('/VipSrc')">
            <div class="grvip_top">
              <div class="grvip_zp">
                <img class="grvip_zp1" src="../assets/vipicon.png" alt="" />
                <img class="grvip_zp2" src="../assets/VIP.png" alt="" />
                <div>{{ svip }}</div>
              </div>
              <!-- <div class="grvip_fh">></div> -->
            </div>
          </router-link>
          <div class="grvip_jd">
            <div class="grvip_jdt">
              <div>vip{{ level }}</div>
              <div>{{ mysvipexp }}/{{ nextsvipexp }}</div>
              <div class="iut">{{ next_level }}</div>
            </div>
          </div>
          <div class="grvip_tab">
            <div>{{ $t("next_level_1") }}：{{ width.toFixed(2) }}%</div>
            <div>{{ $t("total_dep_1") }}：{{ $t("mouy") }}{{ money.toFixed(2) }}</div>
          </div>
          <div class="grvip_jd">
            <div class="grvip_jdt">
              <div>vip{{ level }}</div>
              <!-- <div>{{ mytotalbet }}/{{ nexttotalbet }}</div> -->
              <div class="iut">{{ next_level }}</div>
            </div>
          </div>
          <div class="grvip_tab">
            <div>{{ $t("next_level_1") }}：{{ width.toFixed(2) }}%</div>
            <div>{{ $t("total_dep_1") }}：{{ $t("mouy") }}{{ money2.toFixed(2) }}</div>
          </div>
        </div>
        <div class="yhk_box">
          <div class="gryhk_box">
            <div style="display: flex">
              <div class="gryhk_yy1">{{ $t("withdrawal_1") }}</div>
              <img src="../assets/help.png" style="width: 20px; height: 20px" alt="" />
            </div>
            <div class="gryhk_xjs">
              {{ $t("play_amount_1") }}
            </div>
          </div>
          <div class="grzx_yhk">
            <div class="grvip_jq">
              <div class="jq1">{{ $t('mouy') }}{{ dcoin.toFixed(2) }}</div>
              <div class="jq2">{{ $t("removable_1") }}</div>
            </div>
            <div class="grzx_yh">
              <!-- <div class="grzx_tr">0.00/0.00</div> -->
              <div style="margin-top: 30px;">{{ $t("value_unlocked_1") }}</div>
              <div>{{ coin.toFixed(2) }}</div>
            </div>
          </div>
        </div>
        <div class="zx" @click="Logout">
          <div>
            <img src="../assets/open.png" alt="" />
            <div>{{ $t("Sair") }}</div>
          </div>
          <i v-show="loading" class="el-icon-loading"></i>
        </div>
        <div class="zh">
          <router-link to="/withdraw">
            <div>{{ $t("to_remove_1") }}</div>
          </router-link>
          <router-link to="/gomoney">
            <div>{{ $t("deposit_1") }}</div>
          </router-link>
        </div>
      </el-drawer>
      <router-link to="/message" @click.native="gotoPage('/message')">
        <img src="../assets/img17.png" style="width: 40px; height: 40px;" alt="" />
      </router-link>
    </div>
    <div class="top_dl" v-else>
      <router-link to="/register">
        <el-button @click="drawer = true" type="primary"
          style="margin-left: 16px;  background: linear-gradient( 270deg, #E2A5E5 0%, #ACAAEA 54%, #79C0FE 100%); border: 0px">{{
            $t("log_reg_1") }}</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  login,
  register,
  logout,
  member,
  vip,
  getmoney,
} from "../api/index.js";
import { EventBus } from '../load/event-bus';
export default {
  data() {
    return {
      playername: "",
      phone: "",
      password: "",
      ytr: true,
      drawer: false,
      input: "",
      checked: false,
      disabled: true,
      zc_box: true,
      zc_xs: false,
      drawerr: false,
      Token: "",
      uid: "",
      avatar: "",
      svip: "",
      level: 0,
      next_level: 0,
      mysvipexp: 0,
      nextsvipexp: 0,
      mytotalbet: 0,
      nexttotalbet: 0,
      width: 0,
      money: 0,
      money2: 0,
      phone1: "",
      coin: 0,
      dcoin: 0,
      totalcoin: "",
      token: null, // 确保在 data 中定义了 token
      loading: false,
    };
  },

  watch: {
    checked(newCheckedValue) {
      console.log(newCheckedValue);
      // 当 checked 发生变化时触发
      if (newCheckedValue) {
        this.disabled = false; // 启用按钮
      } else {
        this.disabled = true; // 禁用按钮
      }
    },
  },

  created() {
    EventBus.$on('loginSuccess', (token) => {
      this.Token = token;
      if (this.Token) {
        member().then((res) => {
          if (res) {
            localStorage.setItem("userinfo", JSON.stringify(res.data));
            this.avatar = res.data.avatar;
            this.playername = res.data.user.playername;
            this.uid = res.data.user.uid;
            this.svip = res.data.money.svip;
            this.phone1 = res.data.user.phone;
            this.totalcoin = res.data.money.totalcoin;
            // 将对象转为 JSON 字符串
            // const jsonString = JSON.stringify(userinfo);
          }
        });

        vip().then((res1) => {
          if (res1) {
            this.level = res1.data.exp.mylevel;
            this.next_level = res1.data.exp.next_level;
            this.mysvipexp = res1.data.exp.mysvipexp;
            this.nextsvipexp = res1.data.exp.nextsvipexp;
            this.mytotalbet = res1.data.exp.mytotalbet;
            this.nexttotalbet = res1.data.exp.nexttotalbet;
          }
        });

        getmoney().then((res2) => {
          if (res2) {
            console.log("****************************", res2);
            this.coin = res2.data.user.coin;
            this.dcoin = res2.data.user.ecoin;
          }
        });
      }
    });
  },

  mounted() {
    if (localStorage.getItem("token")) {
      this.Token = localStorage.getItem("token");
      console.log(this.Token);
    }

    if (this.Token) {
      member().then((res) => {
        if (res) {
          if (res.code != 200) return;
          localStorage.setItem("userinfo", JSON.stringify(res.data));
          this.avatar = res.data.avatar;
          this.playername = res.data.user.playername;
          this.uid = res.data.user.uid;
          this.svip = res.data.money.svip;
          this.phone1 = res.data.user.phone;
          this.totalcoin = res.data.money.totalcoin;
        }
      });

      vip().then((res1) => {
        if (res1) {
          if (res1.code != 200) return;
          this.level = res1.data.exp.mylevel;
          this.next_level = res1.data.exp.next_level;
          this.mysvipexp = res1.data.exp.mysvipexp;
          this.nextsvipexp = res1.data.exp.nextsvipexp;
          this.mytotalbet = res1.data.exp.mytotalbet;
          this.nexttotalbet = res1.data.exp.nexttotalbet;
        }
      });

      getmoney().then((res2) => {
        if (res2) {
          if (res2.code != 200) return;
          this.coin = res2.data.user.coin;
          this.dcoin = res2.data.user.ecoin;
        }
      });
    }
    // 将对象转为 JSON 字符串
    // const jsonString = JSON.stringify(userObject);
  },

  methods: {
    copyData() {
      // 复制数据的逻辑
      // 例如，可以使用浏览器的Clipboard API来复制数据到剪贴板
      navigator.clipboard.writeText(this.uid)
        .then(() => {
          // console.log('数据已复制');
          this.$message({
            message: 'success',
            type: 'success'
          });
        })
        .catch((error) => {
          // console.error('复制数据失败:', error);
          this.$message({
            message: 'fails',
            type: 'error'
          });
        });
    },

    gotoPage(path) {
      // console.log('path==>', path);
      EventBus.$emit('updateMenuSelectChange', path);
    },

    zhuc() {
      const confign = {
        playername: this.playername,
        phone: this.phone,
        password: this.password,
      };

      register(confign)
        .then((response) => {
          const token = response.data.token; // 请根据实际情况获取token字段
          console.log(token); // 打印token，确保获取到了
          // 将token存储到localStorage
          localStorage.setItem("token", token);
          this.Token = token;
          // 跳转到首页
          // this.$router.push("/");
          this.drawer = false;
        })
    },

    Logout() {
      this.loading = true;
      logout()
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            localStorage.removeItem("token");
            this.Token = "";
            localStorage.removeItem("userinfo");
            this.userinfo = "";
            // 跳转到首页
            // this.$router.push("/");
            // EventBus.$emit('updateMenuSelectChange', '/index');
          }
        })
        .catch((error) => {
          console.error("Error during login:", error);
        });
    },

    zc_box2() {
      (this.zc_box = false), (this.zc_xs = true);
    },

    qihuan() {
      console.log(123);
      (this.zc_box = true), (this.zc_xs = false);
    },

    // width() {
    //   let aa = this.mysvipexp / this.nextsvipexp;
    //   return aa.toFixed(2) * 100;
    // },
    // money() {
    //   let aa = this.nextsvipexp - this.mysvipexp;
    //   return aa;
    // },
    // width2() {
    //   let aa = this.mytotalbet / this.nexttotalbet;
    //   return aa.toFixed(2) * 100;
    // },
    // money2() {
    //   let aa = this.nexttotalbet - this.mytotalbet;
    //   return aa;
    // },
  },
};
</script>

<style scoped>
.zx .el-icon-loading {
  color: #fff;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-box ::v-deep .el-drawer__body {
  background-color: #000;
}

.link-box {
  text-decoration: none;
}

.topp {
  width: 100%;
  /* width: calc(100% - 250px); */
  height: 70px;
  background-color:#3f4e87;
  /* background: linear-gradient( 270deg, #E2A5E5 0%, #ACAAEA 54%, #79C0FE 100%); */
  left: 0;
  top: 0;
  position: fixed;
  /* z-index: 998; */
  z-index: 9;
  display: flex;
  justify-content: flex-end;
}

.uyt {
  /* width: 40%; */
  height: 100%;
  /* height: 70px; */
  /* float: right; */
  display: flex;
  align-items: center;
  margin-right: 60px;
  gap: 10px;
}

.iopp {
  display: flex;
  width: 300px;
  height: 50px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  /* margin-top: 10px; */
  border-radius: 30px;
  line-height: 50px;
}

.sx {
  margin-top: 15px;
  margin-left: 20px;
}

.je {
  color: #ffce2f;
  width: 70%;
  height: 50px;
  margin-left: 5px;
}

.zj {
  margin-top: 5px;
}

.txtop {
  /* margin-top: 10px;
  margin-left: 20px; */
}

.top_dl {
  float: right;
  color: #fff;
  /* width: 100px; */
  height: 50px;
  line-height: 50px;
  text-align: center;
  /* margin-right: 20%; */
  margin-right: 5%;
  margin-top: 10px;
  border-radius: 10px;
}

.el-drawer.rtl {
  background-color: #000 !important;
}

.dl_a {
  width: 100%;
  height: 30px;
  color: #fff;
  font-size: 30px;
  margin-top: 100px;
  text-align: center;
}

.dl_b {
  width: 100%;
  height: 10px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
}

.dl_box {
  width: 444px;
  height: 50px;
  border: 1px solid rgb(97, 96, 96);
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  border-radius: 10px;
}

.dl_box2 {
  width: 100%;
  height: 50px;
  border: 1px solid rgb(97, 96, 96);
  margin: 0 auto;
  display: flex;
  margin-top: 20px;
  border-radius: 10px;
}

.dl_zi {
  height: 50px;
  font-size: 24px;
  margin-left: 20px;
  text-align: center;
  line-height: 50px;
  color: #fff;
}

.dl_box input {
  width: 80%;
  height: 30px;
  margin-left: 20px;
  line-height: 50px;
  margin-top: 7px;
  background-color: black;
  border: 0px solid black;
  color: #fff;
  font-size: 22px;
  outline: none;
}

.dl_box2 input {
  width: 80%;
  height: 30px;
  margin-left: 20px;
  line-height: 50px;
  margin-top: 7px;
  background-color: black;
  border: 0px solid black;
  color: #fff;
  font-size: 22px;
  outline: none;
}

.dl_box1 {
  width: 80%;
  height: 50px;
  border: 1px solid rgb(97, 96, 96);
  margin: 20px auto;
  border-radius: 10px;
}

.dl_box1 input {
  width: 80%;
  height: 30px;
  margin-left: 20px;
  line-height: 50px;
  margin-top: 7px;
  background-color: black;
  border: 0px solid black;
  color: #fff;
  font-size: 22px;
  outline: none;
}

.dl_zc {
  width: 100%;
  height: 30px;
  color: #fff;
  font-size: 20px;
  margin-top: 30px;
  text-align: center;
}

.dl_ann {
  width: 400px;
  height: 50px;
  margin: 0 auto !important;
  display: inline-block !important;
  line-height: 50px;
  text-align: center;
  background-color: rgb(104, 50, 22);
  color: #fff;
  margin-top: 20px;
  border: 0px solid salmon !important;
}

.dl_xy {
  width: 100%;
  height: 30px;
  display: flex;
  color: #fff;
  margin-top: 40px;
  margin: 20px auto;
  justify-content: center;
}

.dl_po {
  color: darkgray;
}

.dl_srk {
  width: 80%;
  margin: 0 auto;
}

.dl_dx {
  width: 80%;
  height: 20px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  margin-top: 30px;
}

.dl_annf {
  width: 100%;
  text-align: center;
}

.zc_top {
  color: #fff;
  display: flex;
  margin-left: 30px;
  margin-top: 40px;
}

.zc_top div {
  font-size: 30px;
}

.zc_top2 {
  color: #757474;
  font-size: 26px;
  margin: 0 auto;
  margin-top: 20px;
}

.zc_hty {
  width: 95%;
  margin: 0 auto;
}

.zc_ioop {
  width: 80%;
  margin: 0 auto;
}

.grzx_top {
  width: 90%;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
}

.grzx_id {
  width: 220px;
  height: 50px;
  color: #fff;
  margin-left: 20px;
  line-height: 50px;
}

.grzx_id img {
  text-align: center;
  margin-top: 15px;
}

.gezx_fz {
  display: flex;
}

.grzx_dh {
  display: flex;
  width: 147px;
  height: 44px;
  border: 1px solid white;
  color: #fff;
  line-height: 44px;
  border-radius: 10px;
  justify-content: center;
}

.grzx_dh img {
  width: 20px;
  height: 20px;
  margin-top: 11px;
}

.grzx_zp {
  width: 50px;
  height: 50px;
}

.grzx_id {
  width: 100px;
  height: 50px;
}

.grzx_tre {
  display: flex;
}

.grzx_vip {
  width: 90%;
  height: 191px;
  background-image: url(../assets/vipBgc.png);
  margin: 0 auto;
  border-radius: 20px;
}

.grvip {
  width: 95%;
  height: 50px;
  margin: 0 auto;
}

.grvip_zp {
  display: flex;
  width: 120px;
  height: 50px;
}

.grvip_zq1 {
  width: 50px;
  height: 50px;
}

.grvip_zp2 {
  width: 50px;
  height: 25px;
  margin-top: 12px;
}

.grvip_zp div {
  width: 10px;
  height: 50px;
  color: gold;
  font-size: 26px;
  line-height: 50px;
}

.grvip_top {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.grvip_fh {
  color: #fff;
  font-size: 30px;
}

.grvip_jd {
  width: 95%;
  height: 20px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;

  margin-top: 10px;
}

.grvip_jdt {
  display: flex;
  color: #fff;
  justify-content: space-between;
  margin-left: 20px;
}

.iut {
  margin-right: 20px;
}

.grvip_tab {
  width: 95%;
  height: 20px;
  display: flex;
  margin: 0 auto;
  color: #fff;
  justify-content: space-between;
  margin-top: 10px;
}

.yhk_box {
  width: 90%;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 10px auto;
  border-radius: 20px;
}

.gryhk_box {
  width: 98%;
  height: 60px;
  margin: 0 auto;
  margin-top: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(141, 139, 139);
}

.gryhk_yy1 {
  width: 110px;
  height: 30px;
  margin: 0 auto;
  line-height: 70px;
}

.gryhk_yy1 img {
  text-align: center;
}

.gryhk_yy1 {
  font-size: 28px;
}

.gryhk_xjs {
  width: 360px;
  height: 30px;
  margin: 22px auto;
}

.gryhk_box img {
  margin-top: 25px;
}

.grzx_yhk {
  width: 100%;
  height: 130px;
  display: flex;
}

.grvip_jq {
  width: 50%;
  height: 130px;
  color: #fff;
}

.jq1 {
  font-size: 30px;
  margin-left: 10px;
  margin-top: 30px;
}

.jq2 {
  margin-left: 10px;
}

.grzx_yh {
  color: #fff;
  width: 50%;
  height: 130px;
}

.grzx_tr {
  margin-top: 30px;
}

.zx {
  width: 90%;
  height: 50px;
  border: 1px solid white;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin: 0 auto;
  cursor: pointer;
}

.zx img {
  width: 30px;
  height: 30px;
  /* margin-top: 10px;
  margin-left: 10px; */
}

.zx>div {
  color: #fff;
  font-size: 20px;
  /* margin-left: 10px;
  margin-top: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.zx {
  border-radius: 10px;
  margin-top: 20px;
}

.zh {
  width: 95%;
  height: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.zh div {
  width: 140px;
  height: 50px;
  border-radius: 26px;
  background: linear-gradient( 270deg, #E2A5E5 0%, #ACAAEA 54%, #79C0FE 100%);
  color: #fff;
  line-height: 50px;
  text-align: center;
}
</style>
