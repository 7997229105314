<template>
  <div class="zff_box1">
    <div class="dll_box loading-box"
      v-loading="loading"
      element-loading-text="loading..."
      element-loading-spinner="el-icon-loading">
      <img src="../../assets/logo2.7b35e1e5.png" alt="" />
      <div class="zff_ziti">Bem-vindo ao mimosa7. Divirta-se!</div>
      <div class="dl_box">
        <el-input type="text" placeholder="su nombre de usuario" v-model="playername"></el-input>
      </div>
      <div class="dl_box">
        <div class="dl_zi">+55</div>
        <el-input class="num-input" type="number" v-model="phone" placeholder="Tu nùmero de celular"></el-input>
      </div>
      <div class="dl_box" style="margin-top: 20px">
        <el-input placeholder="Please enter password" v-model="password" show-password type="password"></el-input>
      </div>
      <div v-if="invite_code" class="dl_box disabled-btn" style="color:#fff;text-indent:24px;line-height:50px;">
        {{ invite_code }}
      </div>
      <div class="dl_annf" @click="zhuc">
        <div class="iuytre">{{ $t("to_enter_1") }}</div>
      </div>
      <router-link to="/register" style="text-decoration: none;">
        <div class="zuh_zi">
          Ir a iniciar sesión
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { register } from '../../api/index.js'
import { EventBus } from '../../load/event-bus';
export default {
  data() {
    return {
      password: "",
      phone: "",
      playername: '',
      invite_code: '',
      loading: false,
      Token: '',
    }
  },

  methods: {
    zhuc() {
      this.loading = true;

      const confign = {
        playername: this.playername,
        phone: this.phone,
        password: this.password,
        invite_code: this.invite_code
      };

      register(confign)
        .then((response) => {
          this.loading = false;
          const token = response.data.token; // 请根据实际情况获取token字段
          EventBus.$emit('loginSuccess', token);
          console.log(token); // 打印token，确保获取到了
          // 将token存储到localStorage
          localStorage.setItem("token", token);
          this.Token = token;
          // 跳转到首页
          this.$router.push("/");
          // EventBus.$emit('updateMenuSelectChange', '/index');
          // this.drawer = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error during login:", error);
        });
    },

    getParam(path, name) {
      var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
      if (reg.test(path))
        return unescape(RegExp.$2.replace(/\+/g, " "));
      return "";
    }
  },

  watch: {
    checked(newCheckedValue) {
      console.log(newCheckedValue);
      // 当 checked 发生变化时触发
      if (newCheckedValue) {
        this.disabled = false;
        // 启用按钮
      } else {
        this.disabled = true; // 禁用按钮
      }
    },
    zc_box2() {
      (this.zc_box = false), (this.zc_xs = true);
    },
  },

  mounted() {
    if (localStorage.getItem("token")) {
      this.Token = localStorage.getItem("token");
    }
    // 有token值时直接跳转到首页
    if (this.Token) {
      this.$router.replace('/');
      return;
    }
    let local = location.href;
    this.invite_code = this.getParam(local, "invite_code");
    console.log(this.invite_code);
    // this.invite_code = localStorage.getItem('invite_code');
  },

}
</script>

<style scoped>
.disabled-btn {
  background-color: #888;
}

.num-input ::v-deep input::-webkit-outer-spin-button,
.num-input ::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.num-input ::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

.loading-box ::v-deep .el-loading-mask {
  background-color: transparent;
}

.zff_box1 {
  width: 100%;
  height: 1000px;
  background: #475898;
  background-size: 100%;
}

.dll_box {
  width: 440px;
  height: 600px;
  /* background-color: black; */
  margin: 0 auto;
}

.dll_box img {
  width: 160px;
  height: 50px;
  margin: 0 auto;
  margin-left: 130px;
  margin-top: 40px;
}

.zff_ziti {
  color: #a68574;
  text-align: center;
  font-size: 14px;
}

.dl_box {
  color: #fff;
  font-size: 20px;
  padding: 0 10px;
  width: 400px;
  height: 50px;
  border: 1px solid rgb(97, 96, 96);
  margin: 0 auto;
  margin-top: 34px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.dl_box .el-input {
  color: #fff;
  font-size: 20px;
}

.dl_box .el-input ::v-deep input {
  color: #fff;
  border: none;
  background-color: transparent;
}

.dl_dx {
  width: 100%;
  height: 12px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  margin-top: 30px;
}

.dl_zc {
  width: 100%;
  height: 30px;
  color: #fff;
  font-size: 14px;
  margin-top: 30px;
  text-align: center;
}

.iuytre {
  color: #fff;
  width: 300px;
  height: 50px;
  text-align: center;
  /* background: linear-gradient( 180deg, #327C63 0%, #94C796 100%); */
  background: linear-gradient( 270deg, #E2A5E5 0%, #ACAAEA 54%, #79C0FE 100%);

  line-height: 50px;
  margin: 20px auto;
  border-radius: 5px;
}

.zuh_zi {
  width: 450px;
  height: 20px;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.dl_box input {
  height: 40px;
  overflow: hidden;
}
</style>
