<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="cz_box">
        <!-- <div style="width: 100%; height: 50px; margin: 0 auto;">

          <button
            style="width: 300px; height: 50px; background-color: rgb(105, 22, 22); margin-left: 35%; margin-top: 550px; color: #fff; font-size: 20px; border-radius: 10px; border: 1px solid rgb(105, 22, 22); ">{{
              $t('Rechargepresent') }}</button>
        </div> -->
        <img class="image" src="../../assets/bg_5.png" />
        <button class="btn-box" @click="$router.push('/gomoney')">{{$t('Rechargepresent') }}</button>
      </div>
      <el-container>
        <tabBar />
      </el-container>
    </div>
  </div>
</template>

<script>
// import CommonAside from '../../components/CommonAside'
// import TopRes from '../../components/TopRes.vue'
import tabBar from '../../components/tabBar.vue';
export default {
  components: {
    // CommonAside,
    // TopRes,
    tabBar
  }
}
</script>

<style scoped>
.cz_box {
  width: 80%;
  /* height: 650px; */
  /* background-image: url(../../assets/bg_5.png); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: center; */
  /* background-repeat: repeat; */
  /* 2. repeat-x 表示 x轴铺满 */
  /* background-repeat: repeat-x; */
  /* 3. repeat-y 表示 y轴铺满 */
  /* background-size: 100% 650px; */
  margin: 60px auto;
  position: relative;
}

.cz_box .image {
  width: 100%;
  border-radius: 20px;
}

.btn-box {
  width: 300px;
  height: 50px;
  background: linear-gradient( 270deg, #E2A5E5 0%, #ACAAEA 54%, #79C0FE 100%);
  /* margin-left: 35%;
  margin-top: 550px;  */
  color: #fff;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid #E2A5E5;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  cursor: pointer;
}
</style>
